import request from '@/utils/request'

const URL = {
  getOrdersUrl: '/api/procurementOrder/GetOrderList',
  getAuditeOptionsUrl: '/api/procurementOrder/GetAuditeStateOptions',
  getOrderStateOptions: '/api/procurementOrder/GetOrderStateOptions',
  getOrderTypeOptions: '/api/procurementOrder/GetOrderTypeOptions',
  addOrder: '/api/procurementOrder/AddOrder',
  getDetail: '/api/procurementOrder/getOrderDetail',
  downLoadOrder: 'api/procurementOrder/downLoadOrder',
  auditeOrder: 'api/procurementOrder/AuditeOrder',
  changeCourier: 'api/procurementOrder/ChangeCourier',
  receivingOrder: 'api/procurementOrder/ReceivingOrder',
  deleteOrder: 'api/procurementOrder/deleteOrder',
}

// 获取订单信息
export function getOrder(data) {
  return request({
    url: URL.getOrdersUrl,
    method: 'post',
    data,
  })
}

// 获取审核状态信息
export function getAuditeOptions() {
  return request({
    url: URL.getAuditeOptionsUrl,
    method: 'get',
  })
}

// 获取订单类型详情
export function getOrderTypeOptions() {
  return request({
    url: URL.getOrderTypeOptions,
    method: 'get',
  })
}

// 获取订单状态详情
export function getOrderStateOptions() {
  return request({
    url: URL.getOrderStateOptions,
    method: 'get',
  })
}

// 新增订单
export function addProcurementOrder(data) {
  return request({
    url: URL.addOrder,
    method: 'post',
    data,
  })
}

// 查询订单详情
export function getOrderDetail(data) {
  return request({
    url: URL.getDetail,
    method: 'get',
    params: { id: data },
  })
}

// 下载订单详情
export function downLoadOrder(data) {
  return request({
    url: URL.downLoadOrder,
    method: 'get',
    params: { id: data },
    responseType: 'blob',
  })
}

// 审核订单
export function auditeOrder(data) {
  return request({
    url: URL.auditeOrder,
    method: 'post',
    data,
  })
}

// 更新采购订单快递单号
export function changeCourier(data) {
  return request({
    url: URL.changeCourier,
    method: 'post',
    data,
  })
}

// 订单收货
export function receivingOrder(data) {
  return request({
    url: URL.receivingOrder,
    method: 'get',
    params: { id: data },
  })
}

// 删除订单
export function deleteOrder(data) {
  return request({
    url: URL.deleteOrder,
    method: 'get',
    params: { id: data },
  })
}
