<template>
  <div class="content_box">
    <el-card title="采购订单">
      <div slot="header" class="clearfix">
        <span>采购订单</span>
      </div>

      <el-row>
        <el-radio-group
          @change="getPurchaseOrderDate"
          v-model="queryFilter.auditeState"
          size="small"
        >
          <el-radio-button
            v-for="item in AuditeStateOptions"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio-button
          >
        </el-radio-group>

        <span style="float: right">
          <el-button
            size="small"
            @click="pushAddPurchaseOrder"
            type="primary"
            icon="el-icon-edit"
            >新增</el-button
          >
        </span>
      </el-row>

      <el-row style="margin-top: 20px">
        <el-radio-group
          @change="getPurchaseOrderDate"
          v-model="queryFilter.type"
          size="small"
        >
          <el-radio-button
            v-for="item in orderTypeOptions"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio-button
          >
        </el-radio-group>
        <span style="margin: 0 10px"></span>
        <el-radio-group
          @change="getPurchaseOrderDate"
          v-model="queryFilter.status"
          size="small"
        >
          <el-radio-button
            v-for="item in orderStateOptions"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio-button
          >
        </el-radio-group>
      </el-row>

      <el-row style="margin-top: 20px">
        <el-table border :data="tableData" :height="500" style="width: 100%">
          <el-table-column type="index" label="#" fixed="left">
          </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-row
                style="margin-top: 20px; padding-left: 20px"
                v-for="(item, index) in scope.row.detialList"
                :key="item + index"
              >
                <span style="color: red">{{ index + 1 }}</span>
                {{ '.' + item }}
              </el-row>
            </template>
          </el-table-column>
          <el-table-column label="订单编号" width="230">
            <template slot-scope="scope">
              <el-link
                :underline="false"
                @click="handleCopy(scope.row.orderNumber, $event)"
                >{{ scope.row.orderNumber }}</el-link
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="procurementOrdersTypeDisplay"
            width="100"
            label="订单类型"
          >
          </el-table-column>
          <el-table-column
            prop="auditeStateDisplay"
            width="120"
            label="当前审核状态"
          >
          </el-table-column>
          <el-table-column
            prop="stateTypeDisplay"
            width="120"
            label="当前订单状态"
          >
          </el-table-column>
          <el-table-column :width="120" prop="userName" label="订单创建的用户">
          </el-table-column>
          <el-table-column :width="180" prop="createTime" label="创建时间">
          </el-table-column>
          <el-table-column :width="400" prop="remark" label="备注">
          </el-table-column>
          <el-table-column label="编辑" :width="220" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="primary"
                :disabled="scope.row.auditeState != 0"
                icon="el-icon-edit"
                circle
                @click="openAuditeDialog(scope.row.id)"
              ></el-button>
              <el-button
                type="info"
                icon="el-icon-message"
                @click="openOrderDetailDialog(scope.row.id)"
                circle
              ></el-button>
              <el-button
                type="success"
                :disabled="
                  scope.row.auditeState != 1 || scope.row.stateType == 1
                "
                icon="el-icon-box"
                circle
                @click="receivingOrderById(scope.row.id)"
              ></el-button>
              <el-button
                type="danger"
                :disabled="
                  scope.row.auditeState == 1 || scope.row.stateType == 1
                "
                icon="el-icon-delete"
                circle
                @click="deleteOrderById(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px; text-align: right"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryFilter.pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryFilter.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="queryFilter.total"
        >
        </el-pagination>
      </el-row>
    </el-card>

    <!-- 当前订单详情 -->
    <el-dialog title="采购订单详情" :visible.sync="orderDetailDialogVisible">
      <el-row style="margin-top: 10px">
        <el-col :span="8">
          <span>采购订单号:{{ orderDetailData.orderNumber }}</span>
        </el-col>
        <el-col :span="8">
          订单类型:{{ orderDetailData.procurementOrdersTypeDisplay }}
        </el-col>
        <el-col :span="8"
          >审核状态:{{ orderDetailData.auditeStateDisplay }}</el-col
        >
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="8">
          <span>订单状态:{{ orderDetailData.stateTypeDisplay }}</span>
        </el-col>
        <el-col :span="8"> 创建时间:{{ orderDetailData.createTime }} </el-col>
        <el-col :span="8">创建人员:{{ orderDetailData.userName }}</el-col>
      </el-row>

      <el-row style="margin-top: 10px">
        <el-col> 备注:{{ orderDetailData.remark }} </el-col>
      </el-row>

      <el-row
        style="margin-top: 10px"
        type="flex"
        justify="start"
        align="middle"
      >
        <span>快递单号:</span>
        <el-input
          style="width: 200px"
          type="text"
          :disabled="!isChageCourier"
          size="mini"
          v-model="courierNumber"
        ></el-input>
        <el-button
          type="text"
          v-if="!isChageCourier"
          @click="isChageCourier = true"
          >修改</el-button
        >
        <template v-else>
          <el-button type="text" @click="changeOrderCourier">保存</el-button>
          <el-button type="text" @click="cancelCourierChange">取消</el-button>
        </template>
      </el-row>

      <el-row style="margin-top: 10px">
        <el-col :span="16">
          审核备注:{{ orderDetailData.auditeRemark }}
        </el-col>
        <el-col :span="8" style="text-align: right">
          <el-button
            type="primary"
            size="small"
            @click="downLoadOrderTable"
            icon="el-icon-download"
            >订单下载</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="orderDetailData.detial" height="400px">
        <el-table-column label="#" type="index"></el-table-column>
        <!-- <el-table-column
          prop="procurementOrderNumber"
          label="所属订单编号"
        ></el-table-column> -->
        <el-table-column label="商品图片">
          <template slot-scope="scope">
            <el-image
              fit="contain"
              style="width: 50px; height: 50px"
              :src="scope.row.productImageSrc"
              :preview-src-list="[scope.row.productImageSrc]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="商品名称"></el-table-column>
        <el-table-column prop="modelName" label="商品型号"></el-table-column>
        <el-table-column
          prop="productCNBrand"
          label="商品中文品牌"
        ></el-table-column>
        <el-table-column prop="productType" label="商品类型"></el-table-column>
        <el-table-column prop="amount" label="商品数量"></el-table-column>
        <el-table-column prop="price" label="商品价格"></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 当前订单审核页面 -->
    <el-dialog title="采购订单审核" :visible.sync="auditeOrderDialogVisible">
      <el-row style="margin-top: 10px">
        <el-col :span="8">
          <span>采购订单号:{{ auditeOrderData.orderNumber }}</span>
        </el-col>
        <el-col :span="8">
          订单类型:{{ auditeOrderData.procurementOrdersTypeDisplay }}
        </el-col>
        <el-col :span="8"
          >审核状态:{{ auditeOrderData.auditeStateDisplay }}</el-col
        >
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="8">
          <span>订单状态:{{ auditeOrderData.stateTypeDisplay }}</span>
        </el-col>
        <el-col :span="8"> 创建时间:{{ auditeOrderData.createTime }} </el-col>
        <el-col :span="8">创建人员:{{ auditeOrderData.userName }}</el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col> 备注:{{ orderDetailData.remark }} </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col> 快递单号:{{ orderDetailData.courierNumber }} </el-col>
      </el-row>

      <el-table :data="auditeOrderData.detial" height="400px">
        <el-table-column label="#" type="index"></el-table-column>
        <!-- <el-table-column
          prop="procurementOrderNumber"
          label="所属订单编号"
        ></el-table-column> -->
        <el-table-column label="商品图片">
          <template slot-scope="scope">
            <el-image
              fit="contain"
              style="width: 50px; height: 50px"
              :src="scope.row.productImageSrc"
              :preview-src-list="[scope.row.productImageSrc]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="商品名称"></el-table-column>
        <el-table-column prop="modelName" label="商品型号"></el-table-column>
        <el-table-column
          prop="productCNBrand"
          label="商品中文品牌"
        ></el-table-column>
        <el-table-column prop="productType" label="商品类型"></el-table-column>
        <el-table-column prop="amount" label="商品数量"></el-table-column>
        <el-table-column prop="price" label="商品价格"></el-table-column>
      </el-table>
      <el-row style="margin-top: 20px">
        <span>审核备注:</span>
        <el-input
          type="textarea"
          v-model="auditeRemark"
          :rows="1"
          style="width: 100%; margin-top: 10px"
          :maxlength="500"
          show-word-limit
        ></el-input>
      </el-row>
      <el-row style="margin-top: 20px; text-align: right">
        <el-button type="success" @click="auditeOrderConfim(true)"
          >审核通过</el-button
        >
        <el-button type="danger" @click="auditeOrderConfim(false)"
          >审核不通过</el-button
        >
        <el-button type="info" @click="auditeOrderDialogVisible = false"
          >取消</el-button
        >
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import clip from '@/utils/clipboard'
import {
  getOrder,
  getAuditeOptions,
  getOrderTypeOptions,
  getOrderStateOptions,
  getOrderDetail,
  downLoadOrder,
  auditeOrder,
  changeCourier,
  receivingOrder,
  deleteOrder,
} from '@/api/procurementOrder.js'
export default {
  data() {
    return {
      // 请求定时器防止短时间内多次访问
      getOrderTimer: null,
      tableData: [],
      // 分页筛选数据
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: -1,
        auditeState: -1,
        type: -1,
      },
      AuditeStateOptions: [],
      orderTypeOptions: [],
      orderStateOptions: [],
      // 订单详情dialog是否显示
      orderDetailDialogVisible: false,
      // 当前采购详情订单id
      // 采购订单详情
      orderDetailData: {},
      // 审核订单对话框是否显示
      auditeOrderDialogVisible: false,
      // 审核订单Id
      // 审核订单订单内容
      auditeOrderData: {},
      // 审核订单备注内容
      auditeRemark: '',
      // 是否更改快递单号
      isChageCourier: false,
      courierNumber: '',
    }
  },
  methods: {
    // 操作粘贴板使用
    handleCopy(text, event) {
      if (text !== '') {
        clip(text, event)
      }
    },
    // 获取采购订单信息
    getPurchaseOrderDate() {
      if (this.getOrderTimer) {
        clearTimeout(this.getOrderTimer)
      }

      this.getOrderTimer = setTimeout(async () => {
        let { data } = await getOrder(this.queryFilter)

        this.tableData = data.data

        this.queryFilter.total = data.total
      }, 500)
    },
    async getAuditeOptionsData() {
      let { data } = await getAuditeOptions()
      this.AuditeStateOptions = data
    },
    async getOrderTypeOptionsData() {
      let { data } = await getOrderTypeOptions()
      this.orderTypeOptions = data
    },
    async getOrderStateOptionsData() {
      let { data } = await getOrderStateOptions()
      this.orderStateOptions = data
    },
    // 分页大小更改
    handleSizeChange(value) {
      this.queryFilter.pageSize = value
      this.getPurchaseOrderDate()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getPurchaseOrderDate()
    },
    // 跳转新增采购订单页面
    pushAddPurchaseOrder() {
      this.$router.push({ path: '/Purchase/AddPurchaseOrder' })
    },
    // 根据订单id显示订单详情
    async openOrderDetailDialog(id) {
      let { data } = await getOrderDetail(id)
      this.orderDetailData = data
      this.courierNumber = data.courierNumber
      this.isChageCourier = false
      this.orderDetailDialogVisible = true
    },
    // 下载当前订单数据
    async downLoadOrderTable() {
      downLoadOrder(this.orderDetailData.id)
    },
    // 根据审核订单id显示订单详情
    async openAuditeDialog(id) {
      this.auditeOrderId = id
      let { data } = await getOrderDetail(id)
      this.auditeOrderData = data
      this.auditeOrderDialogVisible = true
    },
    // 审核采购订单 true为通过,false为不通过
    async auditeOrderConfim(isPass) {
      let { data } = await auditeOrder({
        id: this.auditeOrderData.id,
        isPass,
        auditeRemark: this.auditeRemark,
      })

      this.auditeOrderData = {}
      this.auditeOrderDialogVisible = false

      this.getPurchaseOrderDate()
    },
    //取消快递单号修改
    cancelCourierChange() {
      this.courierNumber = this.orderDetailData.courierNumber
      this.isChageCourier = false
    },
    // 修改采购订单的快递单号
    async changeOrderCourier() {
      await changeCourier({
        id: this.orderDetailData.id,
        courierNumber: this.courierNumber,
      })

      this.$message.success('当前快递单号更新成功')

      this.openOrderDetailDialog(this.orderDetailData.id)
    },
    // 对当前订单进行收货处理
    receivingOrderById(id) {
      this.$confirm('是否对当前订单进行收货操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          try {
            await receivingOrder(id)
            this.$message({
              type: 'success',
              message: '货物收货成功!',
            })

            this.getPurchaseOrderDate()
          } catch (error) {}
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消操作',
          })
        })
    },
    // 对当前订单进行删除操作
    deleteOrderById(id) {
      this.$confirm('当前操作将会导致订单被删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          try {
            await deleteOrder(id)
            this.$message({
              type: 'success',
              message: '删除成功!',
            })

            this.getPurchaseOrderDate()
          } catch (error) {}
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消操作',
          })
        })
    },
  },
  created() {
    this.getAuditeOptionsData()
    this.getOrderTypeOptionsData()
    this.getOrderStateOptionsData()
    this.getPurchaseOrderDate()
  },
  computed: {},
}
</script>
<style lang="scss" scoped>
.content_box {
  width: 100%;
  height: 100%;
  // background-color: white;
}
</style>
